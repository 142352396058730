<template>
  <div class="content-wrap">
    <div class="create-header">
      <div class="header-top">
        <span>{{ title }}</span>
        <div></div>
      </div>
      <div class="header-bottom">
        <el-form ref="projectForm" :model="formInfo" :rules="ruless" label-width="140px">
          <el-row>
            <el-col :span="8">
              <el-form-item prop="orgId" label="所属组织">
                <el-input v-model.trim="formInfo.orgName" maxlength="128"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
            </el-col>
            <el-col :span="8">
             <el-form-item
                class="select-width"
                label="合同类型"
                prop="contractType"
                :required="true"
              >
                <el-select v-model="formInfo.contractType" placeholder="合同类型" clearable style="width: 220px">
                  <el-option v-for="type in contractTypeData" :key="type.value" :label="type.label" :value="type.value"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
             <el-form-item
                class="select-width"
                label="合同编码"
                prop="contractCode"
                :required="true"
              >
                <el-input v-model="formInfo.contractCode" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="合同名称"
                prop="contractName"
                :required="true"
              >
                <el-input v-model="formInfo.contractName" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="是否为框架合同"
                prop="isStructure"
                :required="true"
              >
              <el-select v-model="formInfo.isStructure" @change="changeNoYes">
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="合同金额（含税）" prop="allAmount">
                <el-input v-model="formInfo.allAmount" maxlength="32"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合同金额（不含税）">
                <el-input v-model="formInfo.allAmountExcludTax"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="是否是否含税金额计税"
                prop="isIncludeTaxAmount"
                :required="true"
              >
              <el-select v-model="formInfo.isIncludeTaxAmount" @change="changeNoYes">
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="合同签订日期"
                prop="writeTime"
                :required="true"
              >
                <el-input v-model="formInfo.writeTime" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="生效日期"
                prop="contractStartTime"
                :required="true"
              >
                <el-input v-model="formInfo.contractStartTime" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="截止日期"
                prop="contractEndTime"
                :required="true"
              >
                <el-input v-model="formInfo.contractEndTime" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="印花税税目"
                prop="stampDuty"
                :required="true"
              >
                <el-input v-model="formInfo.stampDuty" placeholder=""></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="不征税合同"
                prop="isCrossRegion"
                :required="true"
              >
              <el-select v-model="formInfo.isCrossRegion" @change="changeNoYes">
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input v-model="formInfo.remark"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="相对方纳税人识别号" prop="customerNsrsbh">
                <el-input v-model="formInfo.customerNsrsbh" maxlength="32"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="相对方名称">
                <el-input v-model="formInfo.relativeName"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="合同状态 "
                prop="status"
                :required="true"
              >
              <el-select v-model="formInfo.status" @change="changeNoYes">
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>    
      <div class="edit-select">
        <div class="edit-select-foot">
          <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib'
import {
  checkproject,
  checkprojectUniqueCode,
  ReqprojectGetSelectList,
  saveProject,
  selectproject
} from '@/service/project'
import {getInfosByOrgId} from '@/service/paas/paas-service'

export default {
  name: 'create',
  components: { },
  props: {
    lockObject: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      elecData: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      tableData: [],
      chanelData: [
        {
          billingChannel: 'QD-RPA',
          billingChannelName: '全电-RPA',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false
        },
        {
          billingChannel: 'QD-XSD',
          billingChannelName: '机器人X版',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false
        },
        {
          billingChannel: 'QD-LQ',
          billingChannelName: '全电-乐企',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false
        },
      ],
      options: [
        {
          value: false,
          label: '否'
        }, {
          value: true,
          label: '是'
        }
      ],
      title: '创建组织',
      isEdit: false,
      trType: '',
      tMethod: '',
      formInfo: this.lockObject,
      // formInfo: {
      //   id: '',
      //   orgId: 1,
      //   orgName: '',
      //   projectCode: '',
      //   projectName: '',
      //   nsrsbh: '',
      //   nsrmc: '',
      //   isPrepay: '0',
      //   invoiceAmount: 0,
      //   accountOrgId: '',
      //   accountOrgName: '',
      //   customerNsrsbh: '',
      //   relativeName: '',
      //   addressAndPhone: '',
      //   bankAndAccount: '',
      //   taxMethod: '',
      //   taxRelatedType: '',
      //   isCrossRegion: 0,
      // },
      // imageUrl: '',
      // businessLicense: '',
      rules: {
        // name: [
        //   { required: true, message: '请输入组织名称', trigger: 'blur' },
        //   { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' },
        //   { 'validator': this.handleCheckprojectName, trigger: 'blur' }
        // ],
        // taxNo: [
        //   { required: true, message: '请输入税号', trigger: 'blur' },
        //   { pattern: RegexLib.TaxNo, message: '请输入正确的税号', trigger: 'blur' },
        //   { 'validator': this.handleCheckprojectTaxNo, trigger: 'blur' }
        // ],
        // address: [
        //   { required: true, message: '请输入地址', trigger: 'blur' },
        //   { min: 1, max: 128, message: '请输入1到128个字符', trigger: 'blur' }
        // ],
        // phone: [
        //   { required: true, pattern: RegexLib.Telephone, message: '请输入正确电话', trigger: 'blur' },
        // ],
        // bank: [
        //   {required: true, message: '请输入开户银行', trigger: 'blur'}
        // ],
        // uniqueCode: [
        //   {required: true, message: '请输入组织编码!', trigger: 'blur'},
        //   {'validator': this.handleCheckprojectUniqueCode, trigger: 'blur'}
        // ],
        // account: [
        //   {required: true, message: '请输入银行账号', trigger: 'blur'}
        // ],
        // taxpayerType: [
        //   {required: true, message: '请选择纳税人类型', trigger: 'change'}
        // ],
        // isElec: [
        //   {required: true, message: '请选择是否是数电试点企业', trigger: 'blur'}
        // ],

      },
    }
  },
  created () {
    this.isEdit = this.$route.query.isEdit
    if (this.isEdit) {
      this.title = '编辑组织'
      let id = this.$route.query.id
      this.handleSelectproject(id)
      this.renderTable();
    } else {
      this.tableData = this.chanelData
    }
  },
  computed: {
    ruless: function () {
      return this.rules
    }
  },
  mounted () {
    switch (this.formInfo.taxRelatedType) {
      case 1:
        this.trType = '建筑服务'
        break
      case 2:
        this.trType = '出租不动产'
        break
      case 3:
        this.trType = '销售不动产'
        break
      default:
    }
    
    switch (this.formInfo.taxMethod) {
      case 1:
        this.tMethod = '一般计税'
        break
      case 2:
        this.tMethod = '简易征收（老项目）'
        break
      case 3:
        this.tMethod = '简易征收（甲供材）'
        break
      case 4:
        this.tMethod = '简易征收（其他）'
        break
      default:
    }

  },
  methods: {
    async renderTable() {
      const {success, data} = await getInfosByOrgId(this.$route.query.id);
      if (success && data.length > 0) {
        this.tableData = data
      } else {
        this.tableData = this.chanelData
      }
    },
    changeNoYes() {
      this.$refs.formInfo.clearValidate()
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status);
    },    
    // 提交商品信息
    async submitMerchandise() {
      const { success } = await saveProject(this.formInfo);

      const message = this.formInfo.id > 0 ? '修改' : '新增';
      this.saveBtnLoading = false;
      if (success) {
        this.toast(`${message}商品信息成功`, 'success', () => this.handleCancel('success'));
        if (this.scene == 'Billing' && this.isRetail) {
          this.$emit('edit-finish', this.info);
        }
      } else {
        this.toast(`${message}商品信息失败`, 'error');
      }
    },

    // 获取组织详情
    handleSelectproject(id) {
      selectproject(id).then(res => {
        if (res.success) {
          console.log(res.data)
          this.formInfo = res.data
          // this.imageUrl = res.data.fileUrl
        }
      })
    },
    // 禁止输入特殊字符
    handleNoInputSpecialCharacter (e) {
      let flag = RegexLib.TaxNo
      e.target.value = e.target.value.replace(flag, '')
    },
    // 组织编码唯一性校验
    handleCheckprojectUniqueCode (rule, value, callback) {
      checkprojectUniqueCode(this.formInfo).then(res => {
        if (res.success) {
          if (res.data) {
            callback(new Error('该组织编号已经存在'))
          } else {
            callback()
          }
        }
      })
    },
    // 组织税号校验
    handleCheckprojectTaxNo (rule, value, callback) {
      let param = {
        orgId: this.formInfo.orgId,
        orgName: this.formInfo.orgName
      }
      checkproject(param).then(res => {
        if (res.success) {
          if (res.data) {
            callback(new Error('该组织税号已经存在'))
          } else {
            callback()
          }
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  .el-dialog{
    width: 1100px;
  }

  .create-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
    }

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }

    }
  }

  .create-main {
    width: 100%;
    height: 565px;
    background-color: #fff;
    overflow-x: auto;
    margin-bottom: 24px;

    .license-title {
      height: 40px;
      margin: 24px;
      background: rgba(235, 244, 255, 1);
      display: flex;
      align-items: center;
      padding-left: 24px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      span {
        width: 1082px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .license-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .license-img {
        width: 180px;
        height: 240px;
        background: rgba(229, 229, 229, 1);
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        .license-show {
          width: 180px;
          height: 240px;
        }

        img {
          width: 58px;
          height: 58px;
        }
      }

      .el-button {
        width: 120px;
      }
    }
  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;

    span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }
</style>
<style>
  .el-form-item__error {
    top: 30px;
  }
</style>
